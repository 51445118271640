import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import LinkButton from "../components/link-button"
import Container from "../components/container"
import NewsList from "../components/news-list"
import CaseList from "../components/case-list"
import RecentList from "../components/recent-list"
import ArticleCard from "../components/article-card"
import Accordion from "../components/accordion"

// svg images
import beforeDecision from "../images/index/before-decision.svg"
import beforeOperation from "../images/index/before-operation.svg"
import beforeMistake from "../images/index/before-mistake.svg"
import beforeIdentify from "../images/index/before-identify.svg"
import afterDownShape from "../images/index/after-down-shape.svg"
import afterDecision from "../images/index/after-decision.svg"
import afterEngagement from "../images/index/after-engagement.svg"
import afterCommunication from "../images/index/after-communication.svg"
import afterOperation from "../images/index/after-operation.svg"
import caseRetail from "../images/index/case-retail.svg"
import caseFactory from "../images/index/case-factory.svg"
import caseApparel from "../images/index/case-apparel.svg"
import caseRrealestate from "../images/index/case-realestate.svg"
import caseVod from "../images/index/case-vod.svg"
import caseTravel from "../images/index/case-travel.svg"
// import caseHumanresources from "../images/index/case-humanresources.svg"
// import caseEntertainment from "../images/index/case-entertainment.svg"

// resource-column → lg:grid-cols-4
// local components

const AccentSection = ({ title, body, buttonTitle, buttonUrl, children }) => {
  return (
    <section
      className={`bg-gradient-to-r from-gradationAccent-from to-gradationAccent-to relative overflow-hidden text-white`}
    >
      <div className="">
        <div className="w-full absolute z-0 bottom-0">
          <StaticImage
            layout="fullWidth"
            alt=""
            src={"../images/bg-element-s.png"}
            loading="eager"
            placeholder="none"
          />
        </div>
        <div className="relative z-10 py-8 lg:py-14">
          <Container className="lg:flex lg:flex-row-reverse">
            {children}
            <div className="flex-grow lg\:w-1\/2 lg:pr-10">
              <h2 className="text-white text-lg lg:text-3xl font-bold mb-2 lg:mb-4">
                {title}
              </h2>
              <p className="text-xs lg:text-base leading-relaxed lg:leading-relaxed text-gray-50 mb-4 lg:mb-8">
                {body}
              </p>
              <LinkButton text={buttonTitle} url={buttonUrl} type="primary" />
            </div>
          </Container>
        </div>
      </div>
    </section>
  )
}

const FeatureCard = ({ image, title, children, buttonTitle, buttonUrl }) => {
  return (
    <div className="lg:flex lg:flex-col justify-between flex-grow mb-6 lg:mb-0">
      <div className="lg:mb-3">
        {image()}
        <h3 className="text-blue-950 font-bold text-base lg:text-2xl mt-4 mb-3 lg:mt-7 lg:mb-4">
          {title}
        </h3>
        <p className="text-textGray-100 text-xs lg:text-sm leading-relaxed lg:leading-relaxed">
          {children}
        </p>
      </div>
      <div>
        <LinkButton text={buttonTitle} url={buttonUrl} type="normal" />
      </div>
    </div>
  )
}

const IconWithText = ({ text, icon }) => {
  return (
    <div className="flex items-center">
      <img alt="" src={icon} className="w-12 lg:w-16 mr-4 lg:mr-6" />
      <p className="font-bold text-textGray-100 text-xs lg:text-base">{text}</p>
    </div>
  )
}

const SectionTitle = ({ children }) => {
  return (
    <h2 className="font-bold text-center text-xl lg:text-3xl text-blue-950 pb-7 lg:pb-12">
      {children}
    </h2>
  )
}

const IndustryIcon = ({ title, body, icon, url }) => {
  return (
    <Link
      to={url}
      target="_blank"
      className="rounded-xl shadow-lg overflow-hidden hover:shadow-xl bg-white hover:opacity-90 text-center px-6 py-7"
    >
      <div className="flex items-center justify-center mx-auto mb-2">
        <img alt="" src={icon} className="w-12 lg:w-16" />
      </div>
      <p className="font-bold text-blue-950 text-sm lg:text-base mb-1 lg:mb-2 text-center">
        {title}
      </p>
      <p className="text-textGray-100 text-xs lg:text-sm">{body}</p>
    </Link>
  )
}

const Faq = (props) => {
  const itemList = [
    {
      menu:'CDPとはなんですか？',
      contents:'CDPとはカスタマーデータプラットフォームの略称で、企業の顧客に関するデータを管理し、顧客一人ひとりを理解するための基盤のことです。米国や欧州を始めとして、ここ数年で導入する企業が急増しているマーケティングシステムです。詳しくは「CDPの基礎知識」をご覧ください。',
      textlink:
        <LinkButton
          type="normal"
          text="CDPの基礎知識"
          url="/about-cdp/"
        />
    },
    {
      menu:'CDP「INTEGRAL-CORE」は、どのような企業に導入されていますか？',
      contents:'小売・ECサイト・不動産・美容・メディアなど、多種多様な業界で導入いただいています。',
      textlink:
        <LinkButton
          type="normal"
          text="導入企業"
          url="https://www.ever-rise.co.jp/dx-blog/cases-integral-core"
        />
    },
    {
      menu:'CDP「INTEGRAL-CORE」の実際の操作画面を見てみたいです',
      contents:'実際の画面を操作しながらCDP「INTEGRAL-CORE」の基本的な使い方や他社での活用事例、多様な活用シーンを説明いたします。まずは「デモリクエスト」から申請ください。',
      textlink:
        <LinkButton
          type="normal"
          text="デモリクエスト"
          url="/demo-request/"
        />
    },
    {
      menu:'CDP「INTEGRAL-CORE」はベスト・オブ・ブリードの製品ですか？',
      contents:(
        <div>
          はい、ベスト・オブ・ブリードの考え方の製品です。<br/><br/>
          理由としては、既存事業においてCDPを導入する場合、ベスト・オブ・ブリードならすでに導入しているMAツールやCRMツールを使って、データ基盤を構築できるためです。<br/><br/>
          また、新規事業においてCDPを導入する場合も、ビジネスの立ち上げ段階ではどのようなデータの持ち方になるか、施策を行っていくのか要件が見えないため、ツール選定の要件も決められません。つまり、スイート（オールインワン）の製品にした場合、立ち上げ段階では不要な機能にコストを支払うことになったり、逆に事業が成長するにつれて欲しい機能がない、ということがあります。<br/><br/>
          そのため、まずデータの箱（ベスト・オブ・ブリードのCDPなど）を作っておき、初めは低コストで導入できるビジネスフェーズにあったツールを使い、事業が成長し要件が見えてきてから、適切なBIツールや施策ツールを導入していくことで無駄なコストを削減できると考えています。詳しくは「ベスト・オブ・ブリードとは？スイートとの違い、それぞれのメリット・デメリット」もご覧ください。
        </div>
      ),
      textlink:
        <LinkButton
          type="normal"
          text="ベスト・オブ・ブリードとは？スイートとの違い、それぞれのメリット・デメリット"
          url="https://www.ever-rise.co.jp/dx-blog/best-of-breed/"
        />
    },
    {
      menu:'連携ツールはどれくらいありますか？',
      contents:'CDP「INTEGRAL-CORE」は、顧客理解をした後に顧客により良いアプローチをするために、30以上の分析・施策を行うツールと連携できるようになっています。BIツールやMA、プッシュ通知、web接客ツールなどさまざまなテクノロジーに連携できます。',
      textlink:
        <LinkButton
          type="normal"
          text="連携ツール"
          url="/tools/"
        />
    },
    {
      menu:'データが分かれていて複雑なのですが、CDP「INTEGRAL-CORE」を利用することでデータ活用は可能でしょうか？',
      contents:'システム開発会社ならではの技術ノウハウにより、各社のデータ活用の目的やフェーズに合わせて、実現に向けたステップをご提案します。技術視点を持ったデータ活用スペシャリストがお話を伺いますので、まずは検討中の構想をお聞かせください。',
      textlink:
        <LinkButton
          type="normal"
          text="お問合せ"
          url="/contact/"
        />
    },
    {
      menu:'導入するときにサポートはしてもらえますか？',
      contents:'はい。データ活用に初めて取り組む企業でも、技術視点を持ったデータ活用スペシャリストが導入まで伴走しますのでご安心ください。少しでもご不明点があれば都度アドバイスいたします。 詳しくは「CDP導入支援・データ活用サポート」をご覧ください。',
      textlink:
        <LinkButton
        type="normal"
        text="CDP導入支援・データ活用サポート"
        url="/support/"
        />
    },
    {
      menu:'個人情報を扱うためセキュリティ面が気になります',
      contents:(
        <div>
          セキュリティ面には力を入れており、CDP「INTEGRAL-CORE」はISO27001認証も取得しています。<br/><br/>
          AWS上にシステム構築し、データベースの暗号化処理やアクセス制限、管理画面上にて権限管理棟の機能を提供していますが、ご希望の方には専用環境プランもご用意しています。詳しくは「認証・セキュリティの取り組み」をご覧ください。
        </div>
      ),
      textlink:
        <LinkButton
        type="normal"
        text="認証・セキュリティの取り組み"
        url="/security/"
        />
    },
  ]
  return (
    <div>
      {/* itemListに対するmapメソッド */}
      {itemList.map((item) => {
        return (
          <Accordion
            menu = {item.menu}
            contents = {item.contents} //props名 = propsの値
            textlink = {item.textlink} //props名 = propsの値
          />
        )
      })}
    </div>
  );
}


// page content
const Index = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes
  const resourceposts = data.resource.nodes
  const seminarposts = data.seminar.nodes
  const [isExpanded, toggleExpansion] = useState(false)

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title="CDP「INTEGRAL-CORE」【公式】カスタマーデータプラットフォーム"
        isHome={true}
      />
      <Hero type="primary">
        <div className="pt-10 lg:pt-32 pb-20 lg:pb-40 text-center">
          <div className="text-sm lg:text-lg text-blue-400 font-bold text-center mb-4">
            あらゆる顧客接点のデータをCDPで1つに統合
          </div>
          <h1 className="font-medium text-2xl lg:text-4xl mb-4 lg:mb-5">
            深い顧客理解と、自由自在なコミュニケーションを
          </h1>
          <p className="text-xs lg:text-sm mb-4 lg:mb-6">
            CDP「INTEGRAL-CORE」は顧客体験を向上するうえでハードルになるデータのサイロ化を解決し、<br/>
            個人情報も含めた適切なデータ活用をサポートします。
          </p>
          <div className="flex justify-center flex-gap-3">
              <LinkButton text="導入相談" url="/contact/" type="primary" />
          </div>
        </div>
      </Hero>
      <div className="relative">
        <Container>
          <div className="rounded-lg bg-blue-50 p-5 lg:py-8 lg:px-11 shadow-xl lg:flex items-center lg:w-9/12 lg:mx-auto -mt-5 lg:-mt-11 text-center lg:text-left">
            <StaticImage
              width={156}
              src="../images/sponsor-apac-real.png"
              alt="Sponsor APAC Real"
              className="max-w-full mb-2 lg:mb-0 lg:mr-10"
            />
            <div>
              <h2 className="font-bold text-blue-950 text-base lg:text-2xl mb-2">
                日本初の米国CDP協会加盟企業
              </h2>
              <p className="text-textGray-80 text-xs lg:text-sm leading-relaxed lg:leading-relaxed">
                日本で初めて米国CDP協会に登録されたプロダクト「INTEGRAL-CORE」<br/>
                マーケターおよびマーケティング技術者に、顧客データ管理について教育・養成を行う中立なベンダー組織として認められています。
              </p>
            </div>
          </div>
        </Container>
      </div>

      <section className="pt-12">
        <Container>
          <div className="lg:w-9/12 lg:mx-auto">
          <div className="p-1 lg:p-3">
            <StaticImage
              layout="fullWidth"
              alt="導入事例"
              src="../images/index/cases.png"
              loading="lazy"
              placeholder="dominantColor"
            />
          </div>
          </div>
        </Container>
      </section>

      <section className="pt-12 pb-12 lg:pt-24 lg:pb-10">
        <Container>
          <div className="lg:w-9/12 lg:mx-auto mb-7 lg:mb-10">
            <h2 className="font-bold text-xl lg:text-3xl text-center text-gray-700 pb-3 lg:pb-4">
              事業成長に必要な正しい顧客分析と一貫性のある顧客体験
            </h2>
            <p className="text-textGray-100 text-xs lg:text-base leading-relaxed lg:leading-relaxed">
              膨大なデータをもとに顧客のニーズをキャッチし、いかに顧客体験を向上できるかが企業の売上向上にダイレクトに繋がる現代。
              <br />
              CDP「INTEGRAL-CORE」は、企業の顧客データを一元管理し、最適なコミュニケーションを提供する基盤として印象的なOne to Oneマーケティングを実現します。
            </p>
          </div>
          <div className="p-1 lg:p-3 mb-5 lg:mb-6">
            <StaticImage
              layout="fullWidth"
              alt="事業成長に必要な正しい顧客分析と一貫性のある顧客体験"
              src="../images/index/about-integral-core.png"
              loading="lazy"
              placeholder="dominantColor"
            />
          </div>
          <div className="lg:w-9/12 lg:mx-auto lg:mb-12 lg:grid lg:grid-cols-2 gap-6">
            <IconWithText
              text="許諾を得て取得したZero / 1st Party Dataの管理と連携"
              icon={afterOperation}
            />
            <IconWithText
              text="正確なデータを利用した顧客分析や施策結果の可視化"
              icon={afterEngagement}
            />
            <IconWithText
              text="顧客データの一元管理によるスピーディーな意思決定"
              icon={afterDecision}
            />
            <IconWithText
              text="最適なコミュニケーションによる印象的な顧客体験"
              icon={afterCommunication}
            />
          </div>
        </Container>
      </section>

      <AccentSection
        title="CDPによる顧客中心マーケティングの実現"
        body="モノの価値のみでなく、体験に価値を求めるようになっている消費者。CDP「INTEGRAL-CORE」は、あらゆるデータから顧客のニーズを的確に掴み、それぞれの顧客に合わせたコミュニケーションを実現します。詳しくは無料でご覧いただける資料をご覧ください。"
        buttonTitle="製品資料ダウンロード"
        buttonUrl="/resource/integral-core/"
      >
        <StaticImage
          className="w-2/3 mb-2 lg:w-1/3 lg:mb-0 lg:flex-shrink-0"
          alt=""
          src={"../images/integral-core-thumbnail.png"}
          loading="eager"
          placeholder="none"
        />
      </AccentSection>

      <section className="pt-12 pb-4 lg:py-24">
        <Container>
          <div className="lg:w-9/12 lg:mx-auto mb-7 lg:mb-12">
            <h2 className="font-bold text-xl lg:text-3xl text-center text-blue-950 pb-3 lg:pb-4">
              CDP「INTEGRAL-CORE」の特長
            </h2>
          </div>
          <div className="lg:grid lg:gap-12 lg:grid-cols-2">
            <FeatureCard
              image={() => (
                <StaticImage
                  className="w-full"
                  layout="fullWidth"
                  alt="ノーコードで顧客データを統合"
                  src="../images/index/feature-nocode.png"
                  placeholder="dominantColor"
                />
              )}
              title="ノーコードで顧客データを統合"
              buttonTitle="機能について詳しく見る"
              buttonUrl="/function/"
            >
              顧客データをSQLなしで、誰でも簡単にGUIで統合できます。
              <br />
              CDP「INTEGRAL-CORE」独自のSCV（シングル・カスタマー・ビュー）機能により
              <b>顧客データを漏れなく、重複なく個人プロファイル化</b>
              することで、顧客のニーズを正しく可視化できるようになり、最適な顧客コミュニケーションを実現します。
            </FeatureCard>
            <FeatureCard
              image={() => (
                <StaticImage
                  layout="fullWidth"
                  alt="リアルタイムデータの活用"
                  src="../images/index/feature-realtime.png"
                  placeholder="dominantColor"
                />
              )}
              title="リアルタイムデータの活用"
              buttonTitle="リアルタイムデータについて問合せる"
              buttonUrl="/contact/"
            >
              常に変化する顧客の行動や興味関心をリアルタイムに収集・処理できる機能の拡張に取り組んでいます。
              <br />
              CDP「INTEGRAL-CORE」を開発したEVERRISEは、デジタル広告の基盤開発から各種マーケティング領域のデータ活用におけるクラウドインテグレーションを多数手がけており、
              <b>超大量アクセス・超大量データ・高可用性</b>
              のシステム構築を得意としています。データの高速処理により、今までにない新しいブランド体験を生み出します。
            </FeatureCard>
            <FeatureCard
              image={() => (
                <StaticImage
                  layout="fullWidth"
                  alt="専用環境での構築"
                  src="../images/index/feature-environment.png"
                  placeholder="dominantColor"
                />
              )}
              title="専用環境での構築"
              buttonTitle="セキュリティについて詳しく見る"
              buttonUrl="/security/"
            >
              センシティブな顧客データを扱うため、ご希望に合わせて
              <b>専用環境プラン</b>
              も用意しています。個人情報保護が重要視される現代において、CDP「INTEGRAL-CORE」は安心・安全な顧客データプラットフォームを提供するためにセキュリティ面にも力を入れています。
            </FeatureCard>
            <FeatureCard
              image={() => (
                <StaticImage
                  layout="fullWidth"
                  alt="データ活用プレミアムサポート"
                  src="../images/index/feature-support.png"
                  placeholder="dominantColor"
                />
              )}
              title="データ活用プレミアムサポート"
              buttonTitle="サポートについて詳しく見る"
              buttonUrl="/support/"
            >
                「技術視点」を持ったデータ活用スペシャリストが多数在籍しており、
              <b>
                15年以上のマーケティングシステム開発支援で蓄積したノウハウやサポート環境
              </b>
                を提供しています。各社のデータ活用の目的やフェーズに合わせて、実現に向けたステップをご提案します。マーケティング領域だけでなく、企業の攻めのDX推進やビジネス全体をデジタル化する支援も行っています。
            </FeatureCard>
          </div>
        </Container>
      </section>

      <section className="py-12 lg:py-24 bg-blue-50">
        <Container>
          <h2 className="font-bold text-xl lg:text-3xl text-center text-blue-950 pb-7 lg:pb-8 mb-4">
            導入・活用事例
          </h2>
          <CaseList />
        </Container>
      </section>

      <AccentSection
        title="適切な顧客データ活用によるブランド価値の向上"
        body="個人情報は企業ではなく消費者のもの。その認識に立った適切な取り扱いがブランドへの信頼感を左右します。Cookieレス時代の顧客データ活用では、許諾を得て取得した「1st Party Data」の活用が必要であり、そのインフラとしてCDP「INTEGRAL-CORE」が役立ちます。"
        buttonTitle="製品資料ダウンロード"
        buttonUrl="/resource/integral-core/"
      >
        <StaticImage
          className="w-2/3 mb-2 lg:w-1/3 lg:mb-0 lg:flex-shrink-0"
          alt=""
          src={"../images/integral-core-thumbnail.png"}
          loading="eager"
          placeholder="none"
        />
      </AccentSection>

      <section className="py-12 lg:py-24">
        <Container>
          <div className="lg:w-9/12 lg:mx-auto mb-7 lg:mb-10">
            <h2 className="font-bold text-xl lg:text-3xl text-center text-blue-950 pb-7 lg:pb-8 mb-4">
              さまざまな業界でのCDP活用事例
            </h2>
            <p className="text-textGray-100 text-xs lg:text-base leading-relaxed lg:leading-relaxed">
              CDP「INTEGRAL-CORE」は、さまざまな業界に最適化された顧客体験の提供をサポートします。詳しくは無料でご覧いただける業界別の資料をご覧ください。
            </p>
          </div>
          <div className="pt-10 lg:pt-12 grid grid-cols-2 gap-5 lg:gap-8 resource-column">
            <IndustryIcon
              title="製造小売"
              body="アパレル・生活用品など"
              icon={caseApparel}
              url="/resource/case-manufacture-retail/"
            />
            <IndustryIcon
            title="小売"
            body="百貨店・スーパー・コンビニなど"
            icon={caseRetail}
            url="/resource/case-retail/"
            />
            <IndustryIcon
              title="不動産"
              body="賃貸・注文住宅・リフォームなど"
              icon={caseRrealestate}
              url="/resource/case-real-estate/"
            />
            <IndustryIcon
              title="宿泊"
              body="ホテル・旅館など"
              icon={caseTravel}
              url="/resource/case-hotel/"
            />
            <IndustryIcon
              title="メーカー"
              body="家電・食品・化粧品など"
              icon={caseFactory}
              url="/resource/case-manufacture/"
            />
            <IndustryIcon
            title="メディア"
            body="テレビ・ラジオなど"
            icon={caseVod}
            url="/resource/case-media/"
            />
            {
            /*
            <IndustryIcon
              title="人材"
              body="転職・派遣など"
              icon={caseHumanresources}
              url=""
            />
            <IndustryIcon
              title="エンタメ"
              body="映画館・アミューズメントなど"
              icon={caseEntertainment}
              url=""
            />*/}
          </div>
          <div className="text-center pt-10 lg:pt-14">
            <LinkButton
              text="導入事例一覧を見る"
              url="https://www.ever-rise.co.jp/dx-blog/cases-integral-core"
              isExternal={true}
              target="_blank"
            />
          </div>
        </Container>
      </section>

      <section className="py-12 lg:py-24">
        <Container>
          <SectionTitle>よくあるご質問</SectionTitle>
          <Faq />
        </Container>
      </section>

      <div className="py-12 lg:py-24 bg-blue-50">
        <Container>
          <div className="lg:grid lg:grid-cols-2 lg:gap-10">
            <section className="mb-10 lg:mb-0">
              <div className="flex items-center justify-between mb-4 lg:mb-9">
                <h2 className="text-blue-950 text-lg lg:text-2xl font-bold">
                  資料
                </h2>
                <LinkButton type="normal" text="一覧を見る" url="/resource/" />
              </div>
              {resourceposts.length === 0 ? (
                <p className="text-center">資料がありません</p>
              ) : (
                <div className="flex flex-col">
                  {resourceposts.map(post => {
                    return (
                      <div className="mb-3 lg:mb-7 rounded-xl shadow-lg overflow-hidden hover:shadow-xl mb-4 lg:mb-0 bg-white">
                        <Link
                          to={`${data.site.siteMetadata.siteUrl}/resource${post.fields.slug}`}
                        >
                          <ArticleCard
                            key={post.fields.slug}
                            title={post.frontmatter.title}
                            cover={post.frontmatter.cover}
                           />                
                        </Link>
                      </div>
                    )
                  })}
                </div>
              )}
            </section>
            <section className="mb-10 lg:mb-0">
              <div className="flex items-center justify-between mb-4 lg:mb-9">
                <h2 className="text-blue-950 text-lg lg:text-2xl font-bold">
                  セミナー・動画
                </h2>
                <LinkButton type="normal" text="一覧を見る" url="/seminar/?filter=accepting" />
              </div>
              {seminarposts.length === 0 ? (
                <p className="text-center">セミナーがありません</p>
              ) : (
                <div className="flex flex-col">
                  {seminarposts.map(post => {
                    return (
                      <div className="mb-3 lg:mb-7 rounded-xl shadow-lg overflow-hidden hover:shadow-xl mb-4 lg:mb-0 bg-white">
                        <Link
                          to={`${data.site.siteMetadata.siteUrl}/seminar${post.fields.slug}`}
                        >                    
                        <ArticleCard
                          key={post.fields.slug}
                          title={post.frontmatter.title}
                          cover={post.frontmatter.cover}
                          date={post.frontmatter.date}
                        />
                        </Link>
                      </div>
                    )
                  })}
                </div>
              )}
            </section>
          </div>
        </Container>
      </div>

      <AccentSection
        title="直感的に操作できるCDP「INTEGRAL-CORE」"
        body="CDP「INTEGRAL-CORE」は、顧客データをSQLなしで誰でも簡単にGUIで統合でき、独自のSCV（シングル・カスタマー・ビュー）機能により顧客データを漏れなく、重複なく個人プロファイル化することが可能です。デモリクエストで、実際の画面を操作しながらCDP「INTEGRAL-CORE」の基本的な使い方や他社での活用事例、多様な活用シーンを説明いたします。"
        buttonTitle="デモリクエスト"
        buttonUrl="/demo-request/"
      />   

      <section className="py-12 lg:py-24 bg-blue-50">
        <Container>
          <div className="lg:mx-auto">
            <div className="flex items-center justify-between mb-4 lg:mb-9">
              <h2 className="text-blue-950 text-lg lg:text-2xl font-bold">
                ブログ
              </h2>
              <LinkButton
                isExternal={true}
                type="normal"
                text="一覧を見る"
                url="https://www.ever-rise.co.jp/dx-blog/tag/cdp"
                target="_blank"
              />
            </div>
          <RecentList />
          </div>
        </Container>
      </section>

      <section className="py-12 lg:py-24">
        <Container>
          <div className="lg:mx-auto">
            <div className="flex items-center justify-between mb-4 lg:mb-9">
              <h2 className="text-blue-950 text-lg lg:text-2xl font-bold">
                ニュース
              </h2>
              <LinkButton
                isExternal={true}
                type="normal"
                text="一覧を見る"
                url="https://www.ever-rise.co.jp/news/tag/integral-core"
                target="_blank"
              />
            </div>
            <NewsList />
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { collection: { eq: "seminar" } } }
      limit: 3
    ) {
      nodes {
        frontmatter {
          date(formatString: "YYYY.MM.DD")
          title
          type
          cover {
            childImageSharp {
              gatsbyImageData(width: 464)
            }
          }
        }
        fields {
          slug
        }
      }
    }
    seminar: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { collection: { eq: "seminar" } } }
      limit: 3
    ) {
      nodes {
        frontmatter {
          date(formatString: "YYYY.MM.DD")
          title
          cover {
            childImageSharp {
              gatsbyImageData(width: 464)
            }
          }
        }
        fields {
          slug
        }
      }
    }
    resource: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { collection: { eq: "resource" } } }
      limit: 3
    ) {
      nodes {
        frontmatter {
          date(formatString: "YYYY.MM.DD")
          title
          cover {
            childImageSharp {
              gatsbyImageData(width: 464)
            }
          }
        }
        fields {
          slug
        }
      }
    }
  }
`
